body {
    margin: 0px;
    padding: 0px;
    background-color: #f2f2f4;
    font-size: 12px;
}

/* fix para autofill de chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: #ffffff;*/
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #ffffff;
}

/* end fix para autofill de chrome */

/* utils */
.no-bg {
    background-color: transparent !important;
}

.no-border {
    border: none !important;
}

.bg-primary {
    background-color: #293353 !important;
}

.text-primary {
    color: #293353 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.text-white {
    color: #ffffff !important;
}

/* end utils */

/* cards */
.p-card {
    box-shadow: none;
    border: 1px solid #ffffff;
}

/* end cards */

/* forms */
.p-component {
    font-size: 12px;
}

.p-password {
    width: 100%;
}

.p-password-input {
    width: 100%;
}

.field {
    display: flex;
    flex-direction: column;
}

.form-error {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

.form-actions {
    display: flex;
    justify-content: space-between;

}

/* end forms */

/* buttons */
.custom-btn .p-button {
    background-color: #293353;
    border-radius: 3px;
    border-color: #293353;
    padding: 6px 12px;

    .p-button-label {
        font-weight: normal;
        font-size: 13px;
    }
}

.custom-btn .p-button:hover {
    opacity: 0.8;
}

.p-button.p-button-icon-only.p-button-rounded {
    height: 2.5rem;
}

.p-button.p-button-icon-only {
    width: 2.5rem;
}

/* end buttons */

/* links */
a {
    cursor: pointer;
}

a:hover {
    opacity: 0.8;
}

/* end links */

/*overlay*/
.event-info-panel {
    width: 300px;
    font-size: 12px;
}

/*end overlay*/

/* dialog */
.p-dialog-content {
    overflow: hidden;
}

/* end dialog */

/* calendar */

.p-calendar {
    width: 100%;
}

/* end calendar */

/* sidebar */
.p-sidebar-content {
    padding-bottom: 0px;
}
/* end sidebar */

.p-message .p-message-wrapper {
    padding: 0.5rem 1.75rem;
}

/*.p-dialog{
    max-height: 400px !important;
    
}*/

.p-dialog-content{
    overflow-y: auto !important;
}

.p-tag {
    display: inline-flex;
    border-radius: 6px;
}
.p-tag .p-tag-value {
    line-height: 1.5;
}

/* animaciones */
.blink_me {
    animation: blinker 1.5s ease-in-out infinite;
}
.blink_me .p-button {
    background-color: #990000 !important;
    border-color: #990000 !important;
}

@keyframes blinker {
    50% {
        opacity: 0.4;
    }
}
/* fin animaciones */

/* accordion */
.p-accordion-tab {
    margin-bottom: 1rem;
}
.p-accordion-header-link {
    border: 0px;
    background-color: white;
}
.p-accordion-content {
    border: 0px;
    background-color: white;
    padding-top: 0px;
}
/* end accordion */