.input-field label {
    display: block;
    margin-bottom: 3px;
}

.input-field .p-inputtext, .input-field .p-password, .input-field .p-dropdown {
  display: flex;
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
}
.input-field .p-inputtext {
  padding: 0.5rem 0.5rem;
}
.input-field .p-dropdown-clearable .p-inputtext {
  padding-right: 1.5rem !important;
}
.input-field .p-inputswitch {
  margin-top: 1px;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  /*background-color: #1c2439;*/
  background-color: var(--color-success);
}
.input-field-radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

.p-inputtext:enabled:hover, .p-inputtext:enabled:focus, .p-password:enabled:hover, .p-password:enabled:focus, .p-dropdown:not(.p-disabled):hover, .p-dropdown:not(.p-disabled):focus, .p-dropdown:not(.p-disabled).p-focus, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: var(--color-primary);
}

.p-checkbox .p-checkbox-box.p-highlight, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--color-primary);
  background: var(--color-primary);
}

.p-inputtext.ng-dirty.ng-invalid {
    border-color: var(--color-danger);
}

.label-field {
  background-color: #e9e9e9 !important;
}

.p-input-success {
  background-color: var(--color-success) !important;
  color: white;
}
.p-input-warning {
  background-color: var(--color-warning) !important;
}
.p-input-danger {
  background-color: var(--color-danger) !important;
  color: white;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-inputgroup .p-inputwrapper > .p-component {
  width: 100%;
}
.p-inputgroup .p-button {
  height: 100%;
}
.p-inputgroup .p-inputtext, .p-inputgroup .p-dropdown, .p-calendar .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-inputgroup .p-button, .p-calendar .p-button {
  height: 32px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-inputgroup .p-button:not(:last-of-type), .p-calendar .p-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
.p-dropdown .p-dropdown-clear-icon {
  right: 2rem;
}

/*.p-inputtext.ng-dirty.ng-invalid, 
.p-dropdown.ng-dirty.ng-invalid,*/
form.ng-touched .p-inputtext.ng-invalid,
form.ng-touched .p-inputtextarea.ng-invalid,
form.ng-touched p-dropdown.ng-invalid:not(.ng-valid) .p-dropdown,
form.ng-touched p-inputnumber.ng-invalid:not(.ng-valid) > span > input,
form.ng-touched .inputTime.ng-invalid,
form.ng-touched p-calendar.ng-invalid > .p-calendar > .p-inputtext {
  border-color: var(--color-danger);
}

.p-form-field-error {
  padding: 2px;
  color: var(--color-danger);
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: var(--color-warning);
}
