:root {
  --bg-color: #ecf0f6;
  --color-text: #606060;
  --color-primary: #1c2439;
  --color-primary-invert: white;
  --color-secondary: #999999;
  --color-success: #155724;
  --color-danger: #e63757;
  --color-warning: #f6c343;
  --color-info: #398bcd;
  /* menu */
  --menu-bg-color: #1f262c;
  --menu-color: #ffffff;
  --menu-header-bg-color-active: #141414;
  --menu-header-color-active: #ffffff;
  --menu-item-bg-color-hover: #393a3b;
  --menu-item-bg-color-active: #52575b;
  --menu-item-color: #ffffff;
  --menu-item-icon-color: #ffffff;
  --menu-item-color-active: #ffffff;
  --menu-item-icon-color-active: #ffffff;
  /*topbar*/
  --topbar-bg: white;
}
