.p-datatable .p-datatable-wrapper {
  border-radius: 8px;
  border: 1px solid #e3e5e9;
  flex: none;
  height: auto;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #ffffff;
  color: #606060;
  border-bottom: 2px solid #e3e5e9;
  font-size: 13px;
}

.p-datatable .p-datatable-thead > tr > th.p-sortable-column[aria-sort=none] p-sorticon {
  display: none;
}

.p-datatable .p-datatable-tbody > tr > td a, .p-datatable .p-datatable-tbody > tr > td a:hover, .p-datatable .p-datatable-tbody > tr > td a:visited {
  color: var(--color-info);
  text-decoration: none;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 0px;
  font-size: 12px;
  padding: 0.5rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td a > i {
  font-size: 12px;
}
.p-datatable .p-datatable-tbody > tr:nth-child(2n+2) > td {
  background-color: #f9f8fe;
}

/*.p-datatable .p-datatable-tbody .p-button {
    padding: 0.25rem 1rem;
}*/

.p-datatable .p-paginator{
    background-color: transparent;
    padding: 0px;
    margin-top: 0.5rem;
}

.p-datatable .p-paginator .p-paginator-current {
  flex-grow: 1;
  justify-content: start;
  font-size: 12px;
  padding-left: 0px;
  height: auto;
}

.p-datatable .p-paginator .p-paginator-element {
  font-size: 12px;
  height: 1.75rem;
  min-width: 1.75rem;
  background-color: white;
  color: var(--color-text);
  border-radius: 0px;
  border-style: solid;
  border-color: #e9e9e9;
  border-top-width: 1px;
  border-bottom-width: 1px;
  margin: 0px;
}
.p-datatable .p-paginator .p-paginator-element:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left-width: 1px;
}
.p-datatable .p-paginator .p-paginator-element:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right-width: 1px;
}

.p-datatable .p-paginator .p-paginator-element.p-disabled {
    color: #ccc;
}

.p-datatable .p-paginator .p-paginator-element.p-highlight {
  background-color: var(--color-primary);
  color: var(--color-primary-invert);
  border-radius: 0px;
}

.p-datatable .p-paginator .p-dropdown {
  height: auto;
  margin-right: 0px;
}
.p-datatable .p-paginator .p-dropdown-label {
  padding: 0.30rem 0.4rem;
  font-size: 12px;
}
.p-datatable .p-paginator .p-dropdown-trigger {
  width: auto;
  padding: 0.25rem 0.4rem;
}