.p-tabview .p-tabview-nav {
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #e9e9e9;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.p-tabview-panels-no-padding .p-tabview-panels {
    padding: 0px;
}

.p-tabview .p-tabview-nav li.p-highlight {
    border-bottom: 1px solid var(--color-primary);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
    padding: 0.75rem 1rem;
    font-weight: normal;
    text-transform: uppercase;
    border: 0px;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-weight: 700;
    color: var(--color-primary);
    /*border-bottom: 2px solid var(--color-primary);*/
}

.p-dialog-content .p-tabview-panels {
    background-color: #f9f9f9;
}
