html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: var(--bg-color);
  color: var(--color-text);
}

main {
  position: relative;
}

main .content {
  margin: 1.5rem;
}

main.with-topbar {
  padding-top: 75px;
}

main.login-page .content {
  margin: 0px;
  height: 100%;
}

.login-page {
  height: 100vh;
}

.no-wrap {
  flex-wrap: nowrap;
}
.flex-grow {
  flex-grow: 1;
}

.d-block {
  display: block;
}