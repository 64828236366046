.p-card .p-card-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  /*margin-bottom: 0;
  padding-bottom: 0;*/
}
.p-card .p-card-content {
  /*padding-top: 0rem;
  padding-bottom: 0rem;*/
}
.p-card .p-card-header {
  padding: 1.25rem;
}
.p-card-no-padding .p-card-body{
  padding: 0px;
}
.p-card-content-no-padding .p-card-content{
  padding: 0px;
}
.p-card-content-pt-0 .p-card-content{
  padding-top: 0px !important;
}
.p-card-content-pb-0 .p-card-content{
  padding-bottom: 0px !important;
}
.p-card-body-pt-0 .p-card-body{
  padding-top: 0px !important;
}
.p-card-body-pb-0 .p-card-body{
  padding-bottom: 0px !important;
}
.p-card-content > p-table > .p-datatable {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.p-card .p-card-body {
  /*padding-top: 0px;*/
}

.p-dialog-content .p-card {
  background-color: #f9f9f9;
}

.p-card-header-d-none .p-card-header {
  display: none;
}