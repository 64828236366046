* {
  font-family: Arial;
  /*color: var(--color-text);*/
}

pre {
    font-family: monospace;
}

.header-title {
  font-size: 1.625rem;
}
.header-pretitle, .header-posttitle {
  font-size: .625rem;
  color: var(--bs-gray-600);
  letter-spacing: .08em;
  text-transform: uppercase;
}


.text-success {
  color: var(--color-success) !important;
}
.text-warning {
  color: var(--color-warning) !important;
}
.text-danger {
  color: var(--color-danger) !important;
}