.p-button {
  border-radius: 3px;
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-primary-invert);
  padding: 0.40rem 0.75rem;
  font-size: 13px;
}

.p-button:focus {
  box-shadow: none;
}

.p-button-label {
  font-weight: normal;
}

.p-button:hover {
  opacity: 0.9;
}

.p-button-text {
  background: none !important;
  color: var(--color-primary);
}

.p-button-link, .p-button-link:visited, .p-button-link:active {
  cursor: pointer;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  background: none !important;
  color: var(--color-info);
  text-decoration: none;
}

.p-button-link i {
  font-size: inherit;
}

.p-button:enabled:hover {
  opacity: 0.9;
  /*border-color: var(--color-primary);
  background-color: var(--color-primary);*/
}

.p-button-block {
    width: 100%;
}

.p-button.p-button-secondary {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
}
  .p-button.p-button-secondary.p-button-text {
    color: var(--color-secondary);
  }

.p-button.p-button-success {
  border-color: var(--color-success);
  background-color: var(--color-success);
}
.p-button.p-button-success.p-button-text {
  color: var(--color-success);
}

.p-button.p-button-danger {
  border-color: var(--color-danger);
  background-color: var(--color-danger);
}
  .p-button.p-button-danger.p-button-text {
    background: none !important;
    color: var(--color-danger);
  }

.p-button.p-button-warning {
  border-color: var(--color-warning);
  background-color: var(--color-warning);
}
  .p-button.p-button-warning.p-button-text {
    color: var(--color-warning);
  }

.p-button.p-button-info {
  border-color: var(--color-info);
  background-color: var(--color-info);
}
.p-button.p-button-info.p-button-text {
  color: var(--color-info);
}

/* tamaños */
.p-button.p-button-xs {
  padding: 0.25rem 0.75rem;
}
.p-button-xs .p-button-label {
  font-size: 11px;
}
.p-button.p-button-sm {
  padding: 0.35rem 0.75rem;
}
.p-button-sm .p-button-label {
  font-size: 13px;
}
.p-button.p-button-md {
  padding: 0.50rem 0.75rem;
}
.p-button-md .p-button-label {
  font-size: 14px;
}
.p-button.p-button-lg {
  padding: 0.55rem 1rem;
}
.p-button-lg .p-button-label {
  font-size: 16px;
}
.p-button.p-button-xlg {
  padding: 0.70rem 1.5rem;
}
.p-button-xlg .p-button-label {
  font-size: 18px;
}

/* selectbutton */
.custom-select-button.p-selectbutton .p-button {
    padding: 0.9rem 0.9rem;
}
.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover {
  /*background: var(--color-primary);
  border-color: var(--color-primary);*/
  opacity: 0.9;
}
.p-selectbutton {
  padding: 7px;
  background-color: #f9f9f9;
  border-radius: 5px;
  gap: 5px;
}
.p-selectedbutton-scale5 {
  display: flex;
  gap: 5px;

  .p-button {
    width: 20%;
    background-color: #cccccc;
    border: none;
  }
  .p-button.p-highlight {
    background-color: var(--color-primary);
  }
}

.p-button.p-button-text {
  padding: 0.50rem 0.50rem;
}
.p-button.p-button-rounded {
  padding: 0.50rem 0.50rem;
  border-radius: 20px !important;
}
.p-button .p-button-label {
  margin-left: 0.25rem;
}