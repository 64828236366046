.widget {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1.25rem;
    border-radius: 5px;
}

.widget-value {
    font-size: 16px;
    font-weight: bold;
}
.widget-label {
    font-size: 10px;
}
.widget-bg-success {
    background-color: #4CAF50;
    color: white;
}
.widget-bg-warning {
    background-color: #FFC107;
    color: white;
}
.widget-bg-danger {
    background-color: #F44336;
    color: white;
}