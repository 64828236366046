/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

//personalización de los componentes de primeng
@import "assets/css/custom.css";
@import "assets/css/styles.css";